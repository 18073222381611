import { useState } from 'react';
import './App.css';
import Evento from './Evento';

function App() {
  
  return (
    <div className="App">
    

      <Evento/>

    </div>
  );

}

export default App;
