import "./App.css";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import { Alert, Col, Container, Row } from "react-bootstrap";
import logo from "./icone.jpg";
import Swal from "sweetalert2";
import './index.css';
import Modal from "react-modal";
import { FaThumbsUp,
  FaBed,
  FaUtensils,
  FaUserTie,
  FaMicrophone,
  FaMusic,
  FaCar,
  FaFire,
  FaBaby,
  FaDoorClosed,
} from 'react-icons/fa';
import { IoWoman } from "react-icons/io5";
import styled from "styled-components";
import { red } from "@mui/material/colors";
import Loading from "./components/loading";




Modal.setAppElement("#root")

const Evento = () => {

  // function MyComponent() {
  const [isLoading, setIsLoading] = useState(false);
  // }

  const [modalIsOpen, setIsOpen] = useState(false);
  const [ccb_codigo, setCCBCodigo] = useState(localStorage.getItem('ccb_codigo'));



  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  function saveCodigo(cod) {
    localStorage.setItem('ccb_codigo', cod);
    setCCBCodigo(cod);
    setIsOpen(false);
  }

  const [evento, eventoChange] = useState(null);
  const [titulo, tituloChange] = useState(null);
  const [igreja, igrejaChange] = useState(null);


  const [validation, setValidation] = useState(false);

  const [tipo, setTipo] = useState(() => {
    const saved = localStorage.getItem("eventos-tipo");
    return saved || "REUNIAO";
  });

  const tipoChange = (tipo) => {
    localStorage.setItem("eventos-tipo", tipo);
    setTipo(tipo || "REUNIAO");
  };

  useEffect(() => {

    

    fetch(
        "https://ccb.edssolution.com.br/mobile1?evento=" + tipo +'&ccb_codigo=' + ccb_codigo)
        //"http://ftpx.edssolution.com.br:8004/mobile1?evento=" + tipo +'&ccb_codigo=' + ccb_codigo)
        .then((res) => {
          return res.json();
        })
        .then((resp) => {
          console.log(resp);
          eventoChange(resp.data);
          igrejaChange('CCB: ' + resp.igreja);
          tituloChange(resp.titulo);

        });

        setTimeout(() => {
          setIsLoading(false);
        }, 1000);

  }, [tipo, ccb_codigo]);

  const handlesubmit = (e) => {
    
    setTimeout(() => {
      setIsLoading(true);
    }, 100);

    e.preventDefault();
    setTipo(e.nativeEvent.submitter.id);
  };

  const haldleChange = (event) => {
    setCCBCodigo(event.target.value);
    saveCodigo(event.target.value)
    setTipo(tipo || "REUNIAO");
  }

  if (isLoading) {
    return <div className="div-modal"><Loading /></div>;
  }

  return (
      <div className="div0">

        <form className="container" onSubmit={handlesubmit}>
          {/*<div className="card">*/}
            <div>
              <button onClick={openModal} className="btn-codigo-active">
                {igreja}
              </button>
            </div>
          {/*</div>*/}
          <div>
            <div className="div-buttons">
              
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel='Código no relatório'
                overlayClassName="modal-overlay"
                className="modal-wrapper"
              >
                < div className="div-modal">
                  <h6>Igreja {ccb_codigo}</h6>
                  <hr/>
                  <label>Selecione a igreja</label>
                  <div>
                    <Form.Select value={ccb_codigo} onChange={haldleChange}>
                      <option value='21-0299'>Jd Novo Osasco</option>
                      <option value='21-0444'>Jd Tereza Osasco</option>
                      <option value='21-0982'>Jd Vicentina</option>
                      <option value='22-0413'>Cotia Central</option>
                      <option value='22-0427'>Jd Margarida V.Grande</option>
                      

                    </Form.Select>
                    {/* <input id="id_codigo" type="text" value={ccb_codigo} onChange={(e) => setCCBCodigo(e.target.value)}/> */}
                    {/* <a onClick={saveCodigo} className="btn-codigo-save"><FaSave  size={'10%'}/></a> */}
                    <button onClick={closeModal} className="btn-codigo-close">Fechar <FaDoorClosed/></button>

                  </div>
                  
                </div>
              </Modal>
              
              <Button className="btn-evento" id="REUNIAO" type="submit">
                <FaBed size={'100%'}/>
              </Button>

              <Button className="btn-evento"  id="PORTASIRMAOS" type="submit">
                <FaUserTie size={'100%'} />
              </Button>

              <Button className="btn-evento"  id="PORTASIRMAS" type="submit">
                <IoWoman  size={'100%'}/>
              </Button>

              <Button className="btn-evento"  id="COZINHA" type="submit">
                <FaUtensils size={'100%'}/>
              </Button>

              <Button className="btn-evento"  id="SOM" type="submit">
                <FaMicrophone size={'100%'}/>
              </Button>

              <Button className="btn-evento"  id="ORGANISTA" type="submit">
                <FaMusic size={'100%'}/>
              </Button>

              <Button className="btn-evento"  id="CARROS" type="submit">
                <FaCar size={'100%'}/>
              </Button>

              <Button className="btn-evento"  id="BRIGADA" type="submit">
                <FaFire size={'100%'} />
              </Button>

              <Button className="btn-evento"  id="INFANTIL" type="submit">
                <FaBaby size={'100%'} />
              </Button>

            </div>
          </div>

          {/*<div className="card">*/}
            {/* <Container> */}
            <div id="div-subtitulo">
                <span id="id-subtitulo">
                  {titulo}
                </span>
            </div>
          {/*</div>*/}
          <div id="div-rodizio">
            {evento &&
            evento.map((item) => (

                <div id="div-evento">
                  <div>
                    <Button  id="id-campo1">{item.campo1}</Button>
                  </div>

                  <div id="div-campo2">
                    <span id="id-campo2">{item.campo2}</span>
                    {/*<span id="id-msg">{item.msg}</span>*/}
                    <div id="div-msg">
                      <span id="id-msg">{item.msg}</span>
                    </div>
                  </div>



                  <div id="div-campo3">
                    <span id="id-campo3">{item.campo3}</span>
                  </div>

                </div>

            ))}
            {/* <Row className="justify-content-md-center">
    
              </Row> */}
            {/* </Container> */}
          </div>

        </form>

      </div>
  );
};

export default Evento;


// const btn_evento = styled.button`
//   // background-color: red;
//   // border-radius: 45px;
//   // margin: 10px 0px;
//   // color: green;


// `
