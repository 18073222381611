import React from 'react';
    
function Loading() {
  return (
    <div className="loader-container">
      <div className="loader div-modal">Loading...</div>
    </div>
  );
}

export default Loading;